@import url('assets/css/font.css');

:root {
  --white: #ffffff;
  --black: #141415;
  --black2: #18181a;
  --primary: #d80000;
  --grey: #e7e7e9;
  --primary-color: #d80000;
  --black200: #a0a0a7;
  --primary-dark: #870000;
  --grey100: #e2e4eb;
}

body {
  font-family: 'DMSans' !important;
  color: var(--black2) !important;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.form-control:focus,
.uneditable-input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0px 0px #fff inset, 0 0 0px #fff !important;
  outline: 0 none !important;
}

textarea {
  resize: none !important;
}

.text-primary-red {
  color: var(--primary-color) !important;
}

.bg-primary-red {
  background-color: var(--primary-color) !important;
}

.bg-primary-dark {
  background-color: var(--primary-dark) !important;
}

.bg-grey-100 {
  background-color: var(--grey100);
}

.text-white {
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.font-w500 {
  font-weight: 500;
}

.font-BebasNeue {
  font-family: 'BebasNeue' !important;
}

.font-DMSans {
  font-family: 'DMSans';
}

.nav-container {
  border-bottom: 1px solid var(--grey);
  z-index: 2;
}

.nav-link {
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 600 !important;
  text-decoration: none;
  color: var(--white) !important;
  letter-spacing: 0.5px;
}

.header {
  width: 100%;
  height: 755px;
  background: var(--black);
  /* overflow: hidden; */
  position: absolute;
  top: 0;
  z-index: -1;
}

.header-2 {
  height: 490px;
}

.header-3 {
  height: 346px;
  /* height: 700px; */
}

.header img {
  opacity: 0.4;
  width: 100%;
  height: 755px;
  object-fit: cover;
}

.header-2 img {
  height: 490px;
  object-position: 0px 20%;
}

.header-3 img {
  opacity: 0.4;
  width: 100%;
  height: 346px;
  object-fit: cover;
}

.nav-border {
  height: 2px;
  background-color: var(--primary-color);
  margin-top: 8px;
  border-radius: 8px;
}

.header-content-container {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.header-content-container-2 {
  height: 420px;
}

.main-heading {
  letter-spacing: 2px;
  font-size: 80px;
  text-align: center;
}

.sub-heading {
  font-size: 28px;
  margin-top: -20px;
  text-align: center;
}

.text-italic {
  font-style: italic;
}

.bg-white {
  background-color: var(--white);
}

.bg-black {
  background-color: var(--black);
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.justify-sp-between {
  justify-content: space-between;
}

.filled-circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: var(--primary-color);
}

.line {
  width: 412px;
  height: 2px;
  color: var(--primary-color);
}

.borderred-circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid var(--primary-color);
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

.quote-heading {
  font-size: 60px;
}

.vehicle-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  border-width: 0px !important;
  padding: 24px 40px 12px 40px !important;
  min-height: 174px;
}

.vehicle-card:hover {
  background-color: #ffe7e7 !important;
  cursor: pointer;
  /* border: 1px solid var(--primary-color); */
}

.vehicle-card:hover span {
  color: var(--primary-color);
  font-weight: bold;
  /* border: 1px solid var(--primary-color); */
}

.vehicleImage {
  width: 85px;
  /* height: 100px; */
}

.footer-list {
  list-style: none;
  padding-left: 0px;
}

.footer-list li {
  margin: 20px 0px;
  color: var(--white);
  font-weight: 500;
}

.footer-list a,
.footer-privacy-links a {
  text-decoration: none;
  color: var(--white);
}

.footer-list li:first-child {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600 !important;
}

.form-container {
  padding: 0px 200px !important;
}

.quote-rate {
  font-size: 30px;
}

.font-bold {
  font-weight: bold;
}

.border-black-200 {
  border: 1px solid var(--black200);
}

.border-primary-red {
  border: 1px solid var(--primary-color);
}

.button:hover {
  cursor: pointer;
}

.button {
  border-radius: 4px;
  font-size: 20px;
  text-decoration-line: none;
}

.button2 {
  border-radius: 80px;
  font-size: 20px;
}

.back-button {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 10px 40px;
  background-color: var(--white);
}

.submit-button {
  background-color: var(--primary-color);
  color: var(--white);
  padding: 10px 40px;
}

.contact-submit-button {
  border-width: 0px;
}

.border-0 {
  border-width: 0px;
}

@media only screen and (max-width: 768px) {
  .quote-form-container {
    padding: 0px 0px !important;
  }

  .line {
    width: 200px;
  }
}

.h-section-1 {
}

.h-section-1-content {
  padding: 120px 100px;
}

.h-section-1-content h2,
.h-section-2-content-container h2,
.h-section-3 h2,
.h-section-4 h2,
.h-section-6 h2,
.o-section-2 h2,
.o-section-3 h2,
.faq-section h2,
.o-testimonials h2,
.the-whitewood-container h2,
.about-us-content h2 {
  font-size: 80px;
}

.rectangle-1 {
  height: 690px;
  position: absolute;
  right: -120px;
  z-index: -2;
  top: 40px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.instant-quote-button {
  background-color: var(--primary-color);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.position-relative {
  position: relative;
}

.h-section-2,
.o-section-2 {
  width: 100%;
  height: 670px;
}

.h-section-2-img-cont,
.o-section-2-img-cont {
  width: 100%;
  height: 670px;
  background: var(--black);
  /* overflow: hidden; */
  position: absolute;
  z-index: -1;
}

.h-section-2 img,
.o-section-2-img-cont img {
  opacity: 0.4;
  width: 100%;
  height: 670px;
  object-fit: cover;
}

.h-section-2-content-container,
.o-section-2-content-container {
  width: 100%;
  min-height: 670px;
  z-index: 3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.h-list-container,
.o-list-container {
  color: var(--white);
  text-align: left;
  font-size: 20px;
}

.h-list-container ul {
  display: flex;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.h-list-container ul li {
  min-width: 250px;
}

.h-list-container ul li,
.o-list-container ul li {
  margin: 0;
  padding: 12px 0 12px 48px;
  list-style: none;
  background-image: url('./assets/images/TickGreen.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 38px;
}

.transport-desc-container {
  background-image: url('./assets/images/Construction.png');
  background-size: contain;
  background-position-x: -50px;
  background-repeat: no-repeat;
  padding: 240px 0px;
}

.transport-desc-container-2 {
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-self: center;
}

.h-section-4 {
  background-image: url('./assets/images/RecordJed.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.h-section-4 img {
  width: '80%';
  height: 200px;
  object-fit: contain;
}

.h-section-4-1 {
  background-image: url('./assets/images/Rectangle2.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.h-section-4-content {
  padding: 72px 0px;
}

.h-detail {
  padding-right: 160px;
}

.h-section-5 {
  padding: 64px 20px;
}

.h-section-5 h2 {
  font-size: 64px;
}

.h5-img {
  width: 360px;
}

.h-section-5-content {
  padding: 0px 220px;
}

.h-section-6 {
  width: 100%;
  height: 430px;
}

.h-section-6-img-cont {
  width: 100%;
  height: 430px;
  background: var(--black);
  /* overflow: hidden; */
  position: absolute;
  z-index: -1;
}

.h-section-6 img {
  opacity: 0.4;
  width: 100%;
  height: 430px;
  object-fit: cover;
}

.h-section-6 h2,
.o-section-2-1 {
  padding: 0px 120px;
}

.o-section-1 {
  padding: 48px 160px;
}

.faq-card {
  border-radius: 4px;
  width: 80%;
  padding: 12px 24px;
}

.carouselItem {
  background-color: var(--white);
  border-radius: 4px;
  padding: 54px 40px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08),
    0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  margin: 20px;
  min-height: 100%;
  justify-content: center;
}

.active-dot {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: var(--primary-color);
  margin: 0px 8px;
}

.inactive-dot {
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: var(--grey100);
  margin: 0px 8px;
}

.o-testimonials {
  position: relative;
}

.o-testimonials img {
  position: absolute;
  right: 0px;
  width: 600px;
  bottom: 0px;
  z-index: -2;
}

.about-features {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 12px;
}

.feature-chip {
  padding: 10px 14px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  color: var(--primary-color);
}

.feature-chip:hover {
  color: var(--white);
  background-color: var(--primary-color);
}

.blog-list-container h1,
.blog-detail-container h1 {
  font-size: 60px;
}

.blog-card {
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08),
    0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  height: 100%;
}

.blog-card img {
  max-height: 280px;
}

.blog-content {
  padding: 20px 30px;
  min-height: 250px;
}

.blog-content span {
  color: #18181a;
}

.date-time-sep {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ccd0dc;
  margin: 0px 8px;
  border-radius: 8px;
}

.readmore-button {
  font-weight: 600;
  padding: 4px 20px !important;
}

.about-section-1 {
  overflow: hidden;
  background-color: rgba(216, 0, 0, 0.6);
  /* background-color: var(--primary-color); */
}

.about-us-image {
  overflow: hidden;
  padding: 0px !important;
  background-color: var(--black);
}

.about-us-image img {
  width: 100%;
  height: 520px;
  object-fit: cover;
  object-position: 60% 50%;
  opacity: 0.3;
}

.about-us-content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;
  width: 50%;
  z-index: 2;
}

.about-us-1 {
  position: absolute;
  background-image: url('./assets/images/Rectangle2.png');
  width: 74%;
  height: 520px;
  left: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 200px 40px 100px;
  font-size: 24px;
}

.contact-form-container p {
  color: #18181a;
}

.contanct-form-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.underline-primary {
  border-bottom: 0.6px solid var(--primary-color);
}

.contact-section-heading {
  margin-top: 6px;
}

.contact-section p {
  margin: 4px 0px !important;
}

.contact-section a {
  text-decoration-line: none;
}

.back-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  color: var(--black2);
  text-decoration-line: none;
}

.back-container img {
  margin-right: 8px;
}

.blog-detail-image {
  width: 70%;
}

.blog-detail-description-container {
  padding: 0px 100px;
}

.faq-qustion-container:hover,
.button2:hover {
  cursor: pointer;
}

.navbar-toggler {
  border-color: transparent !important;
  box-shadow: none !important;
}

.blog-button {
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.highlight {
  background-color: #e9d2fd;
}

.bolg-heading {
  font-size: 3rem;
  font-family: 'BebasNeue' !important;
  text-align: center;
}

.white-wood-banquet-iframe {
  width: 100%;
  min-height: 750px;
}

.h-section-2 .iframe {
  min-height: 360px;
  width: 100%;
}

/* Snackbar CSS Start */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: 30px;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 30px;
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 30px;
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

/* Snackbar CSS End*/

.form-control.is-valid,
.was-validated .form-control:valid {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  background-image: none !important;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1400px) {
  .h-section-1-content h2,
  .h-section-2-content-container h2,
  .h-section-3 h2,
  .h-section-4 h2,
  .h-section-6 h2,
  .o-section-2 h2,
  .o-section-3 h2,
  .faq-section h2,
  .o-testimonials h2,
  .the-whitewood-container h2,
  .about-us-content h2 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 992px) {
  .o-section-1 {
    padding: 30px 20px;
  }

  .main-heading {
    font-size: 50px;
  }

  .sub-heading {
    font-size: 24px;
  }

  .font-size-22 {
    font-size: 20px;
  }

  .carouselItem {
    padding: 20px 10px;
  }

  .o-section-2-content-container {
    padding-top: 20px;
  }

  .faq-card {
    width: 100%;
    padding: 8px 12px;
  }

  .font-size-20 {
    font-size: 16px;
  }

  .o-section-2 {
    height: 820px;
  }

  .o-section-2-img-cont {
    height: 820px;
  }

  .o-section-2-img-cont img {
    height: 820px;
  }

  .o-section-2-content-container {
    min-height: 820px;
  }

  .about-us-1 {
    height: auto;
    left: 0px;
    right: 0px;
    padding: 40px;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .about-us-content {
    display: flex;
    height: 520px;
    width: 100%;
    z-index: 2;
  }

  .h-section-1-content h2,
  .h-section-2-content-container h2,
  .h-section-3 h2,
  .h-section-4 h2,
  .h-section-6 h2,
  .o-section-2 h2,
  .o-section-3 h2,
  .faq-section h2,
  .o-testimonials h2,
  .the-whitewood-container h2 {
    font-size: 38px;
  }

  .blog-list-container h1,
  .blog-detail-container h1 {
    font-size: 42px;
  }

  .blog-detail-image {
    width: 90%;
  }

  .blog-detail-description-container {
    padding: 0px 20px;
  }

  .transport-desc-container {
    padding: 50px 0px;
  }

  .h-section-4-1 {
    background-size: cover;
  }
}

@media only screen and (min-width: 769px) {
  .quote-submit-button {
    justify-content: flex-end !important;
  }
}

@media only screen and (max-width: 768px) {
  .quote-submit-button {
    justify-content: flex-start !important;
  }

  .form-container {
    padding: 0px 0px !important;
  }

  .line {
    width: 200px;
  }

  .about-us-1 {
    font-size: 18px;
    /* background-image: url(""); */
  }

  .main-heading {
    font-size: 32px;
  }

  .sub-heading {
    font-size: 16px;
    margin-top: 20px;
  }

  .navbar-brand img {
    max-width: 40px !important;
    max-height: 40px !important;
  }

  .footer-privacy-links {
    flex-direction: column;
    align-items: center;
  }

  .h-section-1-content h2,
  .h-section-2-content-container h2,
  .h-section-3 h2,
  .h-section-4 h2,
  .h-section-6 h2,
  .o-section-2 h2,
  .o-section-3 h2,
  .faq-section h2,
  .o-testimonials h2,
  .the-whitewood-container h2 {
    font-size: 30px;
  }

  .h-section-5 h2 {
    font-size: 30px;
  }

  .h-section-6 h2,
  .o-section-2-1 {
    padding: 0px 30px;
  }

  .h-section-1-content {
    padding: 20px 40px;
  }

  .font-size-24 {
    font-size: 16px;
  }

  .h-section-5-content {
    padding: 0px 20px;
  }

  .transport-desc-container-2 {
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .h-section-4-1-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center !important; */
  }
}

@media only screen and (max-width: 1024px) {
  .h-list-container ul {
    flex-wrap: wrap;
    padding-top: 2rem;
  }

  .h-list-container ul li {
    padding-top: 12px !important;
    background-position: left center !important;
  }

  .h-section-2 .iframe {
    min-height: 256px;
  }
}

.text-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.testimonial-carousel li.react-multi-carousel-item {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .testimonial-carousel .react-multi-carousel-dot-list {
    display: none;
  }

  .testimonial-carousel li.react-multi-carousel-item {
    padding-bottom: 0px;
  }

  .h-list-container {
    padding-top: 24px;
  }

  .h-section-2 img,
  .h-section-2 {
    height: 100%;
  }

  .h-list-container ul {
    padding: 0px;
  }

  .h-section-2 .iframe {
    min-height: 190px;
  }

  .gallery-custom-padding-xs {
    padding-top: 3rem !important;
  }
}

@media (max-width: 568px) {
  .testimonial-carousel .react-multi-carousel-dot-list {
    display: none;
  }

  .white-wood-banquet-iframe {
    min-height: 250px;
  }
}
